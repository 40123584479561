/* eslint-disable no-console */

import {register} from 'register-service-worker';
import {alertController} from "@ionic/vue";

const checkForUpdates = (registration) => {
	if (registration && registration.update) {
		registration.update().then(() => {
			console.log('Checked for service worker update.');
		})
	}
}

register(`${process.env.BASE_URL}service-worker.js`, {
	ready(registration) {
		console.log('App is being served from cache by a service worker.');
		
		// Start checking for updates every 5 minutes
		setInterval(() => checkForUpdates(registration), 5 * 60 * 1000); // 5 minutes
	},
	registered() {
		console.log('Service worker has been registered.');
	},
	cached() {
		console.log('Content has been cached for offline use.');
	},
	updatefound() {
		console.log('New service worker is being installed in the background...');
	},
	updated(registration) {
		console.log('A new update has been installed but will be applied after reload.');
		
		// Send a message to the new service worker to activate it
		if (registration.waiting) {
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
		
		window.location.reload();
		// Show an update notification
		/* (async () => {
			const alert = await alertController.create({
				header: 'Update Available',
				message: 'A new version has been downloaded. Please reload the app to apply the update.',
				buttons: [
					{
						text: 'Reload Now',
						handler: () => {
							window.location.reload()
						}
					},
					{
						text: 'Later',
						role: 'cancel'
					}
				],
				backdropDismiss: false
			})
			await alert.present()
		})() */
	},
	offline() {
		console.log('No internet connection found. App is running in offline mode.');
	},
	error(error) {
		console.error('Error during service worker registration:', error);
	},
})