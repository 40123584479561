<template>
  <ion-page ref="root">
    <!-- The header with help text -->
    <FtrHeader :helpText="helptext_lastproposals" />

    <ion-content
        fullscreen
        :color="store.url.includes('kievit') ? 'white' : 'light'"
    >
      <q-layout style="width: 100%; height: 100%">
        <q-page-container>
          <q-page>
            <ion-grid
                style="
                height: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
                width: 100%;
              "
            >
              <ion-row
                  style="
                  overflow-y: auto;
                  padding-top: calc(10px + var(--ion-safe-area-top, 0));
                "
              >
                <!-- LEFT TABLE -->
                <ion-col size-xs="12" size-sm="6">
                  <ion-card
                      class="custom-card"
                      style="margin-right:0;margin-left:0;padding-left: 10px; padding-bottom: 20px"
                  >
                    <ion-card-content style="padding: 16px 0 16px 0">
                      <ion-item style="position: relative; left: -16px">
                        <ion-card-title>
                          <ion-label color="tertiary" style="font-size: 24px"
                          >Links</ion-label
                          >
                        </ion-card-title>
                      </ion-item>

                      <!-- QTable for the LEFT side -->
                      <q-table
                          ref="leftTable"
                          style="left: 3px; width: 98%; margin-top: 6px"
                          :hide-bottom="true"
                          bordered
                          flat
                          :rows="rowsLeft"
                          :columns="columns"
                          row-key="name"
                          :visible-columns="[
                          'brand',
                          'lasttype',
                          'ballsize',
                          'heelsize',
                          'size',
                          'ballwidth',
                        ]"
                          :pagination="{ rowsPerPage: 20 }"
                          :selected-rows-label="getSelectedString"
                          selection="multiple"
                          :selected="selected"
                          v-model:selected="selected"
                          @selection="onSelection"
                      >
                        <!-- Table Header Slot -->
                        <template #header="props">
                          <q-tr :props="props">
                            <q-th
                                v-for="col in props.cols"
                                :key="col.name"
                                :props="props"
                                :style="col.name === 'name'
                                ? 'display:none'
                                : 'background-color: var(--ion-color-secondary); color: white;'"
                            >
                              {{ col.label }}
                            </q-th>
                            <q-th
                                style="
                                background-color: var(--ion-color-tertiary);
                                color: white;
                                text-align: center;
                              "
                            >
                              <i class="fa-solid fa-info"></i>
                            </q-th>
                          </q-tr>
                        </template>

                        <!-- Table Body Slot -->
                        <template #body="props">
                          <q-tr
                              :props="props"
                              @click="
                              props.selected = !props.selected
                            "
                              :id="props.row.lastgroup"
                          >
                            <template v-for="col in props.cols" :key="col.name">
                              <!-- If this column is 'lasttype', show plus/minus for lasttype cycling -->
                              <q-td style="padding-top: 12px; padding-bottom: 12px;"
                                  v-if="col.name === 'lasttype'"
                                  :style="col.name === 'name'
                                  ? 'display:none'
                                  : ''"
                              >

                                <q-btn
                                    style="background: var(--ion-color-light);"
                                    icon="far fa-minus"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="decrementLastType(props.row, 'left')"
                                />
                                <span style="margin: 0 8px">{{ col.value }}</span>
                                <q-btn
                                    style="background: var(--ion-color-light);"
                                    icon="far fa-plus"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="incrementLastType(props.row, 'left')"
                                />
                              </q-td>

                              <!-- If 'size', show plus/minus for size cycling -->
                              <q-td
                                  v-else-if="col.name === 'size'"
                                  :style="col.name === 'name' ? 'display:none' : ''"
                              >
                                <div style="width:100%;display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: start;align-content: center">
                                  <q-btn
                                      icon="far fa-minus"
                                      style="background: var(--ion-color-light);"
                                      size="6px"
                                      round
                                      dense
                                      @click.stop="decrementSize(props.row, 'left')"
                                  />
                                  <span style="margin: 0 8px">{{ col.value }}</span>
                                  <q-btn
                                      icon="far fa-plus"
                                      style="background: var(--ion-color-light);"
                                      size="6px"
                                      round
                                      dense
                                      @click.stop="incrementSize(props.row, 'left')"
                                  />
                                </div>
                              </q-td>

                              <!-- ballwidth => green/red background if in range -->
                              <q-td
                                  v-else-if="col.name === 'ballwidth'"
                                  :style="[
                                  col.value < 2 && col.value > -12
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value !== 'na' ? col.value : 'X' }}
                              </q-td>

                              <!-- ballsize => green/red background if in range -->
                              <q-td
                                  v-else-if="col.name === 'ballsize'"
                                  :style="[
                                  col.value < 3 && col.value > -5
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value }}
                              </q-td>

                              <q-td
                                  v-else-if="col.name === 'heelsize'"
                                  :style="[
                                  col.value < 3 && col.value > -15
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value !== 'na' ? col.value : 'X' }}
                              </q-td>

                              <!-- Otherwise normal cell -->
                              <q-td
                                  v-else
                                  :style="col.name === 'name'
                                  ? 'display:none'
                                  : 'background-color:transparent;color:var(--ion-color-dark)'"
                              >
                                {{ col.value }}
                              </q-td>
                            </template>
                            <!-- Expand/Collapse Button -->
                            <q-td style="text-align: center">
                              <q-btn
                                  size="xs"
                                  style="background: var(--ion-color-warning);color:white"
                                  round
                                  dense
                                  @click.stop="props.expand = !props.expand"
                                  :icon="props.expand ? 'far fa-chevron-up' : 'far fa-chevron-down'"
                              />
                            </q-td>
                          </q-tr>

                          <!-- Expanded row content (Left) -->
                          <q-tr
                              v-if="props.expand"
                              :props="props"
                              style="box-shadow: inset 0 2px 5px 0 rgba(200, 200, 200, 1);"
                          >
                            <q-td
                                :colspan="footData.ballwidth_right ? 6 : 5"
                                style="padding: 1px"
                            >
                              <table
                                  style="
                                  width: 96%;
                                  margin-bottom: 10px;
                                  margin-top: 10px;
                                  margin-left: 2%;
                                "
                              >
                                <thead>
                                <tr style="padding: 1px">
                                  <th></th>
                                  <th
                                      style="
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Ingevoerd
                                  </th>
                                  <th
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Leest
                                    <q-btn
                                        style="position: relative; left: 5px; top: -5px;"
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                    >
                                      <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                      >
                                        De afgeronde waarde van de door
                                        de leverancier gehanteerde waarde
                                        zoals weergegeven in de leestabel
                                      </q-tooltip>
                                    </q-btn>
                                  </th>
                                  <th
                                      style="
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Verschil
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <!-- Lengte row -->
                                <tr style="padding: 1px">
                                  <td>Lengte</td>
                                  <td style="text-align: center">
                                    <p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.footlength_left +
                                        ' + (overmaat = ' +
                                        footData.oversize_left +
                                        ') = '
                                      }}
                                    </p>
                                    <p
                                        style="
                                          display: inline-flex;
                                          font-weight: bold;
                                          margin: 0 0 0 5px;
                                          font-size: 1.1em;
                                        "
                                    >
                                      {{
                                        parseInt(
                                            footData.footlength_incl_oversize_left
                                        )
                                      }}
                                    </p>
                                    <q-btn
                                        style="position: relative; left: 5px; top: -5px;"
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                    >
                                      <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                      >
                                        Lengte + Overmaat
                                      </q-tooltip>
                                    </q-btn>
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ parseInt(props.row.lastlength) }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{
                                        parseInt(props.row.entered_footlength_combined) -
                                        parseInt(props.row.lastlength)
                                      }} mm
                                    </p>
                                  </td>
                                </tr>
                                <!-- Balomvang row -->
                                <tr style="padding: 1px">
                                  <td>Balomvang</td>
                                  <td style="text-align: center">
                                    <p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.ballsize_left +
                                        ' + (' +
                                        footData.insole_left +
                                        '*2) = '
                                      }}
                                    </p>
                                    <p
                                        style="
                                          display: inline-flex;
                                          font-weight: bold;
                                          margin: 0 0 0 5px;
                                          font-size: 1.1em;
                                        "
                                    >
                                      {{
                                        parseInt(
                                            footData.ballsize_incl_insole_left
                                        )
                                      }}
                                    </p>
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ parseInt(props.row.ballsize_last) }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{
                                        parseInt(props.row.ballsize_incl_insole) -
                                        parseInt(props.row.ballsize_last)
                                      }} mm
                                    </p>
                                  </td>
                                </tr>
                                <!-- Balbreedte row if footData.ballwidth_right -->
                                <tr
                                    v-if="footData.ballwidth_right"
                                    style="padding: 1px"
                                >
                                  <td>Balbreedte</td>
                                  <td style="text-align: center">
                                    {{ footData.ballwidth_left }}
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.ballwidth_last }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.ballwidth }} mm
                                    </p>
                                  </td>
                                </tr>

                                <tr
                                    v-if="footData.heelsize_right"
                                    style="padding: 1px"
                                >
                                  <td>Hielwreef</td>
                                  <td style="text-align: center">
                                    {{ footData.heelsize_right }}
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.heelsize_last }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.heelsize }} mm
                                    </p>
                                  </td>
                                </tr>

                                </tbody>
                              </table>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </ion-card-content>
                  </ion-card>
                </ion-col>

                <!-- RIGHT TABLE -->
                <ion-col size-xs="12" size-sm="6">
                  <ion-card
                      class="custom-card"
                      style="margin-left: 0;margin-right:0;padding-left: 10px; padding-bottom: 20px"
                  >
                    <ion-card-content style="padding: 16px 0 16px 0">
                      <ion-item style="position: relative; left: -16px">
                        <ion-card-title>
                          <ion-label color="tertiary" style="font-size: 24px"
                          >Rechts</ion-label
                          >
                        </ion-card-title>
                      </ion-item>

                      <!-- QTable for the RIGHT side -->
                      <q-table
                          ref="rightTable"
                          style="left: 3px; width: 98%; margin-top: 6px;"
                          :hide-bottom="true"
                          bordered
                          wrap-cells
                          flat
                          :rows="rowsRight"
                          :columns="columns"
                          row-key="name"
                          :visible-columns="[
                          'brand',
                          'lasttype',
                          'ballsize',
                          'heelsize',
                          'size',
                          'ballwidth',
                        ]"
                          :pagination="{ rowsPerPage: 20 }"
                          :selected-rows-label="getSelectedString"
                          selection="multiple"
                          :selected="selected"
                          v-model:selected="selected"
                          @selection="onSelection"
                      >
                        <template #header="props">
                          <q-tr :props="props">
                            <q-th
                                v-for="col in props.cols"
                                :key="col.name"
                                :props="props"
                                :style="col.name === 'name'
                                ? 'display:none'
                                : 'background-color: var(--ion-color-secondary); color: white;'"
                            >
                              {{ col.label }}
                            </q-th>
                            <q-th
                                style="
                                text-align: center;
                                background-color: var(--ion-color-tertiary);
                                color: white;
                              "
                            >
                              <i class="fa-solid fa-info"></i>
                            </q-th>
                          </q-tr>
                        </template>

                        <template #body="props">
                          <q-tr
                              :props="props"
                              @click="
                              props.selected = !props.selected
                            "
                              :id="props.row.lastgroup"
                          >
                            <template v-for="col in props.cols" :key="col.name">
                              <!-- If 'lasttype', show plus/minus for lasttype cycling -->
                              <q-td
                                  style="padding-top: 12px;padding-bottom: 12px;"
                                  v-if="col.name === 'lasttype'"
                                  :style="col.name === 'name'
                                  ? 'display:none'
                                  : ''"
                              >
                                <q-btn
                                    icon="far fa-minus"
                                    style="background: var(--ion-color-light);"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="decrementLastType(props.row, 'right')"
                                />
                                <span style="margin: 0 8px">{{ col.value }}</span>
                                <q-btn
                                    icon="far fa-plus"
                                    style="background: var(--ion-color-light);"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="incrementLastType(props.row, 'right')"
                                />
                              </q-td>

                              <!-- If 'size', show plus/minus for size cycling -->
                              <q-td
                                  v-else-if="col.name === 'size'"
                                  :style="col.name === 'name' ? 'display:none' : ''"
                              >
                                <div style="width:100%;display: flex;flex-wrap: nowrap;flex-direction: row;justify-content: start;align-content: center">
                                <q-btn
                                    icon="far fa-minus"
                                    style="background: var(--ion-color-light);"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="decrementSize(props.row, 'right')"
                                />
                                <span style="margin: 0 8px">{{ col.value }}</span>
                                <q-btn
                                    icon="far fa-plus"
                                    style="background: var(--ion-color-light);"
                                    size="6px"
                                    round
                                    dense
                                    @click.stop="incrementSize(props.row, 'right')"
                                />
                                </div>
                              </q-td>

                              <!-- ballwidth => green/red background if in range -->
                              <q-td
                                  v-else-if="col.name === 'ballwidth'"
                                  :style="[
                                  col.value < 2 && col.value > -12
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value !== 'na' ? col.value : 'X' }}
                              </q-td>

                              <!-- ballsize => green/red background if in range -->
                              <q-td
                                  v-else-if="col.name === 'ballsize'"
                                  :style="[
                                  col.value < 3 && col.value > -5
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value }}
                              </q-td>

                              <q-td
                                  v-else-if="col.name === 'heelsize'"
                                  :style="[
                                  col.value < 3 && col.value > -15
                                    ? 'background-color:rgba(0, 155, 0, 0.5); color:white'
                                    : 'background-color:rgba(155, 0, 0, 0.5); color:white'
                                ]"
                              >
                                {{ col.value !== 'na' ? col.value : 'X' }}
                              </q-td>

                              <!-- Otherwise normal cell -->
                              <q-td
                                  v-else
                                  :style="col.name === 'name'
                                  ? 'display:none'
                                  : 'background-color:transparent;color:var(--ion-color-dark)'"
                              >
                                {{ col.value }}
                              </q-td>
                            </template>

                            <!-- Expand/Collapse button -->
                            <q-td style="text-align: center">
                              <q-btn
                                  size="xs"
                                  style="background: var(--ion-color-warning);color: white"
                                  round
                                  dense
                                  @click.stop="props.expand = !props.expand"
                                  :icon="props.expand ? 'far fa-chevron-up' : 'far fa-chevron-down'"
                              />
                            </q-td>
                          </q-tr>

                          <!-- Expanded row content (Right) -->
                          <q-tr
                              v-if="props.expand"
                              :props="props"
                              style="box-shadow: inset 0 2px 5px 0 rgba(200, 200, 200, 1);"
                          >
                            <q-td
                                :colspan="footData.ballwidth_right ? 6 : 5"
                                style="padding: 1px"
                            >
                              <table
                                  style="
                                  width: 96%;
                                  margin-bottom: 10px;
                                  margin-top: 10px;
                                  margin-left: 2%;
                                "
                              >
                                <thead>
                                <tr style="padding: 1px">
                                  <th></th>
                                  <th
                                      style="
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Ingevoerd
                                  </th>
                                  <th
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Leest
                                    <q-btn
                                        style="position: relative; left: 5px; top: -5px;"
                                        round
                                        flat
                                        icon="fal fa-info-square"
                                        size="xs"
                                    >
                                      <q-tooltip
                                          max-width="50%"
                                          style="font-size: 16px"
                                      >
                                        De afgeronde waarde, etc.
                                      </q-tooltip>
                                    </q-btn>
                                  </th>
                                  <th
                                      style="
                                        border-bottom: 1px
                                          rgba(155, 155, 155, 1) solid;
                                      "
                                  >
                                    Verschil
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <!-- Lengte row -->
                                <tr style="padding: 1px">
                                  <td>Lengte</td>
                                  <td style="text-align: center">
                                    <p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.footlength_right +
                                        ' + (overmaat = ' +
                                        footData.oversize_right +
                                        ') = '
                                      }}
                                    </p>
                                    <p
                                        style="
                                          display: inline-flex;
                                          font-weight: bold;
                                          margin: 0 0 0 5px;
                                          font-size: 1.1em;
                                        "
                                    >
                                      {{
                                        parseInt(
                                            footData.footlength_incl_oversize_right
                                        )
                                      }}
                                    </p>
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ parseInt(props.row.lastlength) }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{
                                        parseInt(props.row.entered_footlength_combined) -
                                        parseInt(props.row.lastlength)
                                      }} mm
                                    </p>
                                  </td>
                                </tr>
                                <!-- Balomvang row -->
                                <tr style="padding: 1px">
                                  <td>Balomvang</td>
                                  <td style="text-align: center">
                                    <p style="display: inline-flex; margin: 0">
                                      {{
                                        footData.ballsize_right +
                                        ' + (' +
                                        footData.insole_right +
                                        '*2) = '
                                      }}
                                    </p>
                                    <p
                                        style="
                                          display: inline-flex;
                                          font-weight: bold;
                                          margin: 0 0 0 5px;
                                          font-size: 1.1em;
                                        "
                                    >
                                      {{
                                        parseInt(
                                            footData.ballsize_incl_insole_right
                                        )
                                      }}
                                    </p>
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ parseInt(props.row.ballsize_last) }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{
                                        parseInt(props.row.ballsize_incl_insole) -
                                        parseInt(props.row.ballsize_last)
                                      }} mm
                                    </p>
                                  </td>
                                </tr>
                                <!-- Balbreedte row if footData.ballwidth_right -->
                                <tr
                                    v-if="footData.ballwidth_right"
                                    style="padding: 1px"
                                >
                                  <td>Balbreedte</td>
                                  <td style="text-align: center">
                                    {{ footData.ballwidth_right }}
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.ballwidth_last }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.ballwidth }} mm
                                    </p>
                                  </td>
                                </tr>
                                <tr
                                    v-if="footData.heelsize_right"
                                    style="padding: 1px"
                                >
                                  <td>Hielwreef</td>
                                  <td style="text-align: center">
                                    {{ footData.heelsize_right }}
                                  </td>
                                  <td
                                      style="
                                        background-color: rgba(230, 230, 230, 0.5);
                                        text-align: center;
                                      "
                                  >
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.heelsize_last }}
                                    </p>
                                  </td>
                                  <td style="text-align: center">
                                    <p style="font-weight: bold; margin: 0">
                                      {{ props.row.heelsize }} mm
                                    </p>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </ion-card-content>
                  </ion-card>
                </ion-col>

                <!-- Sticky FAB Buttons: share and go to Models -->
                <q-page-sticky class="fixed-bottom-right" :offset="fabPos">
                  <q-btn-group rounded>
                    <!-- Share button -->
                    <q-btn
                        fab
                        tappable="true"
                        class="needsclick"
                        :loading="submitting"
                        rounded
                        icon="fal fa-share-from-square"
                        style="
                        background: var(--ion-color-secondary-tint);
                        color: var(--ion-color-light);
                        padding-left: 30px;
                        padding-right: 20px;
                        border-right: 1px solid rgba(255, 255, 255, 0.85);
                      "
                        :disable="draggingFab"
                        @click="shareSelectedModels()"
                        v-touch-pan.prevent.mouse="moveFab"
                    >
                      <template #loading>
                        <q-spinner-hourglass class="on-left" />
                      </template>
                    </q-btn>

                    <!-- Go to Models button -->
                    <q-btn
                        fab
                        tappable="true"
                        style="
                        background: var(--ion-color-secondary-tint);
                        color: var(--ion-color-light);
                        padding-left: 30px;
                        padding-right: 20px;
                        display: flex;
                        flex-wrap: nowrap !important;
                      "
                        rounded
                        :loading="submitting"
                        @click="goSelectedModels()"
                        icon-right="fal fa-chevron-right"
                        label="Modellen"
                        :disable="draggingFab"
                        v-touch-pan.prevent.mouse="moveFab"
                    >
                      <template #loading>
                        <q-spinner-hourglass class="on-left" />
                      </template>
                    </q-btn>
                  </q-btn-group>
                </q-page-sticky>
              </ion-row>
            </ion-grid>
          </q-page>
        </q-page-container>
      </q-layout>
    </ion-content>
  </ion-page>
</template>

<script setup>
/* ------------------------------------------------------------------------------
 Imports
 ------------------------------------------------------------------------------ */
import {
  alertController,
  IonBackButton,
  IonButtons,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonToolbar,
  useIonRouter,
  IonBadge,
} from "@ionic/vue";

import { post } from "aws-amplify/api";
import {
  QBtn,
  QBtnGroup,
  QLayout,
  QPage,
  QPageSticky,
  QTable,
  QTd,
  QTh,
  QTr,
  useQuasar,
  copyToClipboard,
} from "quasar";

import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { DataStore, SortDirection } from "aws-amplify/datastore";
import { DevFootcareModel, DevModelCollection } from "@/models";
import { useGlobalStore } from "@/store/global";
import { Share } from "@capacitor/share";
import { uploadData } from "aws-amplify/storage";

import FtrHeader from "../components/FtrHeader.vue";
import { helptext_lastproposals } from "../locales/HelptTextContent";

/* ------------------------------------------------------------------------------
 Setup & Refs
 ------------------------------------------------------------------------------ */
const store = useGlobalStore();
const route = useRoute();
const router = useRouter();
const ionRouter = useIonRouter();
const $q = useQuasar();

const submitResult = ref([]);
const fabPos = ref();
const draggingFab = ref(false);

const lastsLeft = ref([]);
const lastsRight = ref([]);
const footData = ref([]);
const rowsLeft = ref([]);
const rowsRight = ref([]);
const selected = ref([]);
const leftTable = ref(null);
const rightTable = ref(null);
const dbModel = ref();
const submitting = ref(false);

let category;
let filter1;
let filter2;
let contentType;

/* ------------------------------------------------------------------------------
 Fabs & moveFab
 ------------------------------------------------------------------------------ */
function moveFab(ev) {
  if (
      ev.position.top > 150 &&
      ev.position.left > 20 &&
      ev.position.left < window.innerWidth - 120
  ) {
    draggingFab.value = ev.isFirst !== true && ev.isFinal !== true;
    fabPos.value = [fabPos.value[0] - ev.delta.x, fabPos.value[1] - ev.delta.y];
    localStorage.setItem("fabPosLastPage", JSON.stringify(fabPos.value));
  } else {
    console.log("too high");
  }
}

if ("fabPosLastPage" in localStorage) {
  fabPos.value = JSON.parse(localStorage.getItem("fabPosLastPage"));
} else {
  fabPos.value = [28, 28];
}

/* ------------------------------------------------------------------------------
 Pull data from store.lastProposals
 ------------------------------------------------------------------------------ */
lastsLeft.value = store.lastProposals.lastsLeft;
lastsRight.value = store.lastProposals.lastsRight;
footData.value = store.lastProposals.footData;
store.footData = footData.value;

// Check route to figure out model usage
if (route.path.includes("osa") && route.path.includes("last")) {
  dbModel.value = DevFootcareModel;
  contentType = "osa";
  category = "category";
  filter1 = "modelnumber";
  filter2 = "colornumber";
} else if (route.path.includes("osb") && route.path.includes("last")) {
  dbModel.value = store.Models;
  contentType = "osb";
  category = "category";
  filter1 = "brand";
  filter2 = "modelnumber";
}

/* ------------------------------------------------------------------------------
 Build Row Objects for the QTable
 ------------------------------------------------------------------------------ */
function buildRowFromItem(item, side) {
  // e.g. footData.footlength_left, oversize_left, etc.
  const fd = store.lastProposals.footData;
  const row = {
    // difference fields
    ballsize:
        parseInt(item.ballsize) - parseInt(fd[`ballsize_incl_insole_${side}`] || 0),
    ballwidth:
        (item.ballwidth !== null ? parseInt(item.ballwidth) - parseInt(fd[`ballwidth_${side}`]) : 'na'),
    heelsize: parseInt(item?.heelinstepsize) - parseInt(fd?.[`heelsize_${side}`] || 'na'),
    // original last data
    ballsize_last: item.ballsize,
    heelsize_last: item?.heelinstepsize,
    ballwidth_last: parseInt(item.ballwidth),
    brand: item.brand,
    name: item.lastgroup,
    ballsize_incl_insole: fd[`ballsize_incl_insole_${side}`],
    calculate_default_insole: item.calculate_default_insole,
    heelinstepsize: item.heelinstepsize,
    id: `${side}_${item.id}`,
    lastgroup: item.lastgroup,
    lastlength: item.lastlength,
    lasttype: item.lasttype,
    size: item.shoesize,
    supplier: item.supplier,
    targetgroup: item.targetgroup,

    // foot data for expansions
    entered_footlength: fd[`footlength_${side}`],
    oversize: fd[`oversize_${side}`],
    entered_footlength_combined:
        fd[`footlength_${side}`] + fd[`oversize_${side}`],
    entered_ballsize: fd[`ballsize_${side}`],
    entered_insole: fd[`insole_${side}`],

    selected: false,
  };
  return row;
}

// Populate rowsLeft
for (const item of lastsLeft.value) {
  console.log(item)
  rowsLeft.value.push(buildRowFromItem(item, "left"));
}
// Populate rowsRight
for (const item of lastsRight.value) {
  rowsRight.value.push(buildRowFromItem(item, "right"));
}

// Sort rowsLeft by closeness to 0 in ballsize
rowsLeft.value.sort((a, b) => {
  return Math.abs(0 - a.ballsize) - Math.abs(0 - b.ballsize);
});

// reorder rowsRight so it lines up by lastgroup
const tempRows = rowsRight.value.slice();
rowsRight.value.length = 0;
rowsLeft.value.forEach((leftRow) => {
  const match = tempRows.find((r) => r.lastgroup === leftRow.lastgroup);
  if (match) rowsRight.value.push(match);
});

/* ------------------------------------------------------------------------------
 Table Columns
 ------------------------------------------------------------------------------ */
const columns = [
  {
    name: "name",
    required: true,
    label: "Id",
    align: "left",
    field: (row) => row.name,
    format: (val) => `${val}`,
  },
  { name: "brand", align: "left", label: "Merk", field: "brand" },
  {
    name: "lasttype",
    align: "left",
    label: "Leesttype",
    field: "lasttype",
  },
  { name: "lastgroup", align: "left", label: "Leestgroup", field: "lastgroup" },
  {
    name: "size",
    align: "left",
    label: "Maat",
    field: "size",
  },
  {
    name: "ballsize",
    align: "left",
    label: "Balomvang",
    field: "ballsize",
    sortable: true,
  },
];

// If footData has a ballwidth for left foot, show Balbreedte
if (
    store.lastProposals.footData.ballwidth_left != null &&
    !isNaN(store.lastProposals.footData.ballwidth_left)
) {
  columns.push({
    name: "ballwidth",
    align: "left",
    label: "Balbreedte",
    field: "ballwidth",
    sortable: true,
  });
}

if (
    store.lastProposals.footData.heelsize_left != null &&
    !isNaN(store.lastProposals.footData.heelsize_left)
) {
  columns.push({
    name: "heelsize",
    align: "left",
    label: "Hielwreef",
    field: "heelsize",
    sortable: true,
  });
}

/* ------------------------------------------------------------------------------
 Size increment & decrement
 - Finds a bigger/smaller size item in the same lasttype/lastgroup/brand
 - Rebuilds the row with updated stats
 ------------------------------------------------------------------------------ */
async function incrementSize(row, side) {
  const filtered = await DataStore.query(
      store.Lasts,
      (c) =>
          c.and((c) => [
            c.brand.eq(row.brand),
            c.lasttype.eq(row.lasttype),
            c.lastgroup.eq(row.lastgroup),
          ]),
      {
        sort: (s) =>
            s.shoesize(SortDirection.ASCENDING)
      }
  );

  // sort by shoesize
  //filtered.sort((a, b) => a.shoesize - b.shoesize);

  // find current item by matching the row.size
  const idx = filtered.findIndex(x => x.shoesize === row.size);
  if (idx !== -1 && idx < filtered.length - 1) {
    const newItem = filtered[idx + 1];
    const newRow = buildRowFromItem(newItem, side);
    // preserve selection
    newRow.selected = row.selected;

    // Replace the row in rowsLeft or rowsRight
    if (side === "left") {
      rowsLeft.value = rowsLeft.value.map(r => (r.id === row.id ? newRow : r));
    } else {
      rowsRight.value = rowsRight.value.map(r => (r.id === row.id ? newRow : r));
    }
  } else {
    $q.notify({
      color: "red-5",
      message: "Geen grotere maat gevonden voor deze leest.",
    });
  }
}

async function decrementSize(row, side) {

  const filtered = await DataStore.query(
      store.Lasts,
      (c) =>
          c.and((c) => [
            c.brand.eq(row.brand),
            c.lasttype.eq(row.lasttype),
            c.lastgroup.eq(row.lastgroup),
          ]),
      {
        sort: (s) =>
            s.shoesize(SortDirection.ASCENDING)
      }
  );

  //filtered.sort((a, b) => a.shoesize - b.shoesize);

  // find current item
  const idx = filtered.findIndex(x => x.shoesize === row.size);
  if (idx > 0) {
    const newItem = filtered[idx - 1];
    const newRow = buildRowFromItem(newItem, side);
    newRow.selected = row.selected;

    if (side === "left") {
      rowsLeft.value = rowsLeft.value.map(r => (r.id === row.id ? newRow : r));
    } else {
      rowsRight.value = rowsRight.value.map(r => (r.id === row.id ? newRow : r));
    }
  } else {
    $q.notify({
      color: "red-5",
      message: "Geen kleinere maat gevonden voor deze leest.",
    });
  }
}


/* ------------------------------------------------------------------------------
 Increment / Decrement lasttype
 (same brand/lastgroup/size, cycle among lasttypes)
 ------------------------------------------------------------------------------ */
async function incrementLastType(row, side) {
  const filtered = await DataStore.query(
      store.Lasts,
      (c) =>
          c.and((c) => [
            c.shoesize.eq(row.size),
            c.lastgroup.eq(row.lastgroup),
          ]),
      {
        sort: (s) =>
            s.ballsize(SortDirection.ASCENDING)
      }
  );
  //console.log(filtered)
  // sort by lasttype (alphabetically, or a custom approach)
  //filtered.sort((a, b) => a.lasttype.localeCompare(b.lasttype));

  const idx = filtered.findIndex((x) => x.lasttype === row.lasttype);
  if (idx !== -1 && idx < filtered.length - 1) {
    const newItem = filtered[idx + 1];
    const newRow = buildRowFromItem(newItem, side);
    newRow.selected = row.selected;

    if (side === "left") {
      rowsLeft.value = rowsLeft.value.map((r) => (r.id === row.id ? newRow : r));
    } else {
      rowsRight.value = rowsRight.value.map((r) => (r.id === row.id ? newRow : r));
    }
  } else {
    $q.notify({
      color: "red-5",
      message: "Geen volgende leesttype gevonden (zelfde maat).",
    });
  }
}

async function decrementLastType(row, side) {
  console.log(row)
  const filtered = await DataStore.query(
      store.Lasts,
      (c) =>
          c.and((c) => [
            c.shoesize.eq(row.size),
            c.lastgroup.eq(row.lastgroup),
          ]),
      {
        sort: (s) =>
            s.ballsize(SortDirection.ASCENDING)
      }
  );

  //filtered.sort((a, b) => a.lasttype.localeCompare(b.lasttype));

  const idx = filtered.findIndex((x) => x.lasttype === row.lasttype);
  if (idx > 0) {
    const newItem = filtered[idx - 1];
    const newRow = buildRowFromItem(newItem, side);
    newRow.selected = row.selected;

    if (side === "left") {
      rowsLeft.value = rowsLeft.value.map((r) => (r.id === row.id ? newRow : r));
    } else {
      rowsRight.value = rowsRight.value.map((r) => (r.id === row.id ? newRow : r));
    }
  } else {
    $q.notify({
      color: "red-5",
      message: "Geen vorig leesttype gevonden (zelfde maat).",
    });
  }
}

/* ------------------------------------------------------------------------------
 onSelection => sync Left/Right row selection
 ------------------------------------------------------------------------------ */
function onSelection({ rows, added, evt }) {
  if (!rows.length) {
    return;
  }
  const row = rows[0];
  // if user clicked a 'left' row => sync to right
  if (row.id.includes("left")) {
    const matchedLeft = rowsLeft.value.find((r) => r.id === row.id);
    if (matchedLeft) {
      matchedLeft.selected = added;
      // find matching row on the right by lastgroup
      const matchRight = rowsRight.value.find(
          (r) => r.lastgroup === matchedLeft.lastgroup
      );
      if (matchRight) {
        matchRight.selected = added;
      }
    }
  } else if (row.id.includes("right")) {
    const matchedRight = rowsRight.value.find((r) => r.id === row.id);
    if (matchedRight) {
      matchedRight.selected = added;
      const matchLeft = rowsLeft.value.find(
          (l) => l.lastgroup === matchedRight.lastgroup
      );
      if (matchLeft) {
        matchLeft.selected = added;
      }
    }
  }

  // We do a simplified approach: normal multi-select or single?
  // If you have advanced shift/ctrl logic, keep it here.
  // Example: if not shift/ctrl => reset selection
  if (!evt || (!evt.shiftKey && !evt.ctrlKey)) {
    selected.value = added ? rows : [];
  } else {
    // SHIFT or CTRL could add them
    if (added) {
      rows.forEach((r) => {
        if (!selected.value.includes(r)) {
          selected.value.push(r);
        }
      });
    } else {
      rows.forEach((r) => {
        const i = selected.value.indexOf(r);
        if (i > -1) {
          selected.value.splice(i, 1);
        }
      });
    }
  }
}

/* ------------------------------------------------------------------------------
 getSelectedString => gather the user’s selected row data
 ------------------------------------------------------------------------------ */
async function getSelectedString() {
  // The "selected" array has both left & right rows. We only need unique combos.
  // We'll build an array of "model" proposals from DataStore.
  const left = rowsLeft.value.filter((r) => r.selected);
  const right = rowsRight.value.filter((r) => r.selected);
  const lastgroups = selected.value.slice(); // each row

  const urlModels = [];
  for (let i = 0; i < lastgroups.length; i++) {
    const row = lastgroups[i];
    let temp = await DataStore.query(
        store.Models,
        (c) =>
            c.and((c) => [
              c.lastgroup.eq(row.lastgroup),
              c.sizerangeMin.le(row.size),
              c.sizerangeMax.ge(row.size),
              c[store.organisation].eq(true),
            ]),
        {
          sort: (s) =>
              s
                  .modelnumber(SortDirection.DESCENDING)
                  .colornumber(SortDirection.DESCENDING),
        }
    );

    // filter by lasttype on left and right if both present
    const lastLeft = left.find((le) => le.lastgroup === row.lastgroup);
    const lastRight = right.find((re) => re.lastgroup === row.lastgroup);
    if (lastLeft && lastRight) {
      temp = temp.filter((model) =>
          model.lasttypes
              .toString()
              .includes(lastLeft.lasttype.substring(0, 8))
      );
      temp = temp.filter((model) =>
          model.lasttypes
              .toString()
              .includes(lastRight.lasttype.substring(0, 8))
      );
    }

    for (const model of temp) {
      const modelObj = {
        modelID: model.id,
        id: model.id,
        category: model.category,
        modelnumber: model.modelnumber,
        modelname: model.modelname,
        colorname: model.colorname,
        filename: model.filename,
        lastgroup: model.lastgroup,
        brand: model.brand,
        attributes: model.attributes,
        colornumber: model.colornumber,
        lasttypes: model.lasttypes,
        lasttype_left: lastLeft ? lastLeft.lasttype : null,
        lasttype_right: lastRight ? lastRight.lasttype : null,
        size_left: lastLeft ? lastLeft.size : null,
        size_right: lastRight ? lastRight.size : null,
        sizerangeMin: model.sizerangeMin,
        sizerangeMax: model.sizerangeMax,
        prices: model.prices,
        price: store.getModelPrice(model),
      };
      if (model.brand === 'Hartjes') {
        // Your mapping array
        const size_ids = [
          { maat_id: 0, size: 2.5 },
          { maat_id: 1, size: 3 },
          { maat_id: 2, size: 3.5 },
          { maat_id: 3, size: 4 },
          { maat_id: 4, size: 4.5 },
          { maat_id: 5, size: 5 },
          { maat_id: 6, size: 5.5 },
          { maat_id: 7, size: 6 },
          { maat_id: 8, size: 6.5 },
          { maat_id: 9, size: 7 },
          { maat_id: 10, size: 7.5 },
          { maat_id: 11, size: 8 },
          { maat_id: 12, size: 8.5 },
          { maat_id: 13, size: 9 },
          { maat_id: 14, size: 9.5 },
          { maat_id: 15, size: 10 },
          { maat_id: 16, size: 10.5 },
          { maat_id: 17, size: 11 },
          { maat_id: 18, size: 11.5 },
          { maat_id: 19, size: 12 },
          { maat_id: 20, size: 12.5 },
          { maat_id: 21, size: 13 },
          { maat_id: 22, size: 13.5 },
        ];
        /**
         * Returns the maat_id for a given shoe size.
         * @param {number} size - The shoe size (e.g., 7, 7.5, 10)
         * @returns {number|null} - The corresponding maat_id, or null if none found
         */
        function getMaatIdFromSize(size) {
          // Use Array.find to locate the entry with matching size
          const found = size_ids.find(entry => entry.size === size);
          // If found, return maat_id; otherwise return null
          return found ? found.maat_id : null;
        }
        const size_id = getMaatIdFromSize(modelObj.size_left);
        const stock = model.stock_volumes;
        console.log(stock)
        function getStockFromMaatId(size_id) {
          // Use Array.find to locate the entry with matching size
          const found = stock.find(entry => entry.maat_id === size_id);
          // If found, return maat_id; otherwise return null
          return found ? found.vrd_aant : null;
        }
        modelObj.stock = getStockFromMaatId(size_id);
        console.log(modelObj);
      }
      urlModels.push(modelObj);
    }
  }
  return urlModels;
}

/* ------------------------------------------------------------------------------
 goSelectedModels => route to /osb/models/
 ------------------------------------------------------------------------------ */
async function goSelectedModels() {
  if (selected.value.length > 0) {
    submitting.value = true;
    const urlModels = await getSelectedString();

    store.resetStoreValues();
    store.modelCollection = urlModels;
    await router.push("/osb/models/");
    submitting.value = false;
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Selecteer in de tabel minimaal één leest!",
    });
  }
}

/* ------------------------------------------------------------------------------
 shareSelectedModels => share link to pre-saved JSON
 ------------------------------------------------------------------------------ */
async function shareSelectedModels() {
  submitting.value = true;
  if (selected.value.length > 0) {
    const urlModels = await getSelectedString();
    const id = Date.now();

    try {
      // Save JSON in S3
      const result = await uploadData({
        path: "public/" + id + ".json",
        data: JSON.stringify(urlModels),
        options: {
          contentType: "text/html",
        },
      });
      console.log("Succeeded: ", result);
    } catch (error) {
      submitting.value = false;
      console.log("Error : ", error);
    }

    // Also store in DataStore
    const collection = await DataStore.save(
        new DevModelCollection({
          models: id + ".json",
          activeModels: null,
          footData: null,
          isLink: "true",
          category: null,
          lastgroup: null,
        })
    );

    const params = {
      category: store.category,
      collectionId: collection.id,
      isLink: true,
    };

    let title = "";
    if (store.logged_in_user["custom:organisation"].includes("Hanssen")) {
      title =
          "Footcare heeft een aangepaste selectie orthopedisch schoeisel met u gedeeld.";
    } else {
      title =
          "Fittr heeft een aangepaste selectie orthopedisch schoeisel met u gedeeld.";
    }

    try {
      await Share.share({
        title: title,
        text: "Dit overzicht is speciaal voor u samengesteld en verzonden met de Fittr app!",
        url: store.shareUrl + JSON.stringify(params),
        dialogTitle: "Kies een app!",
      });
    } catch (e) {
      // fallback for browsers that don't have Share API
      async function presentAlertConfirm() {
        const alert = await alertController.create({
          header: "Delen is helaas niet beschikbaar in deze browser!",
          message:
              "Wilt u de link naar het modeloverzicht naar het klembord kopieren?",
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              id: "cancel-button",
            },
            {
              text: "Ja",
              id: "confirm-button",
              handler: async () => {
                copyToClipboard(store.shareUrl + JSON.stringify(params)).catch(
                    () => {
                      // fail silently
                    }
                );
              },
            },
          ],
        });
        return alert.present();
      }
      await presentAlertConfirm();
      submitting.value = false;
    }
    submitting.value = false;
    return store.shareUrl + JSON.stringify(params);
  } else {
    $q.notify({
      color: "red-5",
      position: "center",
      textColor: "white",
      icon: "fas fa-triangle-exclamation",
      message: "Selecteer in de tabel minimaal één leest!",
    });
  }
}

/* ------------------------------------------------------------------------------
 Expose help text
 ------------------------------------------------------------------------------ */
const helptext_lastproposalsRef = ref(helptext_lastproposals);
</script>

<style>
.q-btn__content {
  flex-wrap: nowrap;
}
</style>

<style scoped>
ion-content {
  --padding-start: 0;
  --padding-end: 0;
}

/* table styling from your snippet */
.q-slider .q-slider__track-container--h {
  opacity: 0;
}

ion-row {
  height: 100%;
}

.selected {
  background-color: rgba(246, 141, 35, 0.3);
}

.q-table td {
  padding: 8px 8px;
  font-size: 10px;
}

.q-table th {
  padding: 9px 3px;
  font-size: 10px;
  font-weight: bold;
}

.q-table tr {
  padding: 8px 8px;
  font-size: 10px;
}
</style>