<template>
  <IonPage>
    <FtrHeader :helpText="helptext_3dscan_page"/>

    <IonContent :color="store.url.includes('kievit') ? 'white' : 'light'" style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start; padding: 10px;">
      <ion-grid>
        <ion-row>
          <!-- Left Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="4.5" size-xl="4.5">
            <ion-card style="padding: 0; margin-top: 8px; margin-left: 8px; margin-right: 0;" class="custom-card">
              <ion-card-content style="padding: 12px">
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="width: 100%; aspect-ratio: 4 / 5; border: 1px solid var(--ion-color-dark); border-radius: 6px">
                        <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                          <div style="width: 100%; height: 100%; border-radius: 5px" ref="target_left"></div>
                        </transition>
                        <q-inner-loading :showing="visible_left">
                          <q-spinner-hourglass size="50px" color="secondary"/>
                          <p style="font-size:1.5em"><b>{{ loading_text_left }}</b></p>
                        </q-inner-loading>
                      </div>
                    </ion-col>


                    <!-- Manual rotate & move controls -->
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
                        <ion-button @click="rotateModelOnYPlane('left', 1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnYPlane('left', -1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('left', -1, 0)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 1, 0)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, -1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, 1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <!-- Middle Column: measurements & actions -->
          <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="3" size-xl="3">
            <ion-card v-if="width_left && width_right" class="custom-card" style="padding: 0; margin-top: 8px; margin-left: 8px; margin-right: 8px; margin-bottom: 16px;">
              <ion-card-content style="padding-inline-start: 10px; padding-inline-end: 10px; display: flex; flex-direction: column;">
                <q-markup-table separator="vertical" dense flat :wrap-cells="true" style="width: 100%">
                  <thead>
                  <tr>
                    <th style="text-align: center; font-size: 10px; font-weight: bold"></th>
                    <th style="padding: 0; text-align: center; font-size: 10px; font-weight: bold">Links</th>
                    <th style="padding: 0; text-align: center; font-size: 10px; font-weight: bold">Rechts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Lengte:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(length_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(length_right) }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Omvang:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(circumference_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(circumference_right) }}</td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">Breedte:</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(width_left) }}</td>
                    <td style="padding: 0; text-align: center; font-size: 12px">{{ Math.round(width_right) }}</td>
                  </tr>
                  </tbody>
                </q-markup-table>
              </ion-card-content>
            </ion-card>

            <ion-card class="custom-card" style="padding: 0; margin: 8px">
              <ion-card-content style="display: flex; flex-direction: column">
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'upload')" style="font-size: 12px">
                  <i style="padding-right: 6px" class="fa-regular fa-cloud-arrow-up"></i>
                  Upload STL/PLY
                </ion-button>
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'library')" style="margin-top: 12px;font-size: 12px">
                  <i style="padding-right: 6px" class="fa-regular fa-rectangle-list"></i>
                  Bibliotheek
                </ion-button>
                <ion-button v-if="files_loaded_left && files_loaded_right" color="danger" style="margin-top: 12px;font-size: 12px" @click="removeScans">
                  <i style="padding-right: 6px" class="fa-regular fa-eraser"></i>
                  Remove Scans
                </ion-button>
                <ion-button color="secondary" v-if="files_loaded_left && files_loaded_right" @click="navigate" style="margin-top: 12px;font-size: 12px">
                  <i style="padding-right: 6px" class="fa-regular fa-chevron-right"></i>
                  Verder
                </ion-button>
              </ion-card-content>
            </ion-card>
            <ion-card style="padding: 0; background-color: #ffffff;">
              <ion-card-content style="display: flex; flex-direction: column; align-items: center; text-align: center;gap:4px">

                <!-- App Icon -->
                <img tappable @click="downloadScannerApp" src="../assets/img/scan_icon.png" alt="FittrScan App Icon" style="width: 120px; height: 120px; border-radius: 18.5%; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);"/>

                <!-- Heading -->
                <div style="font-size: 14px; font-weight: bold; color: #333; margin: 8px 16px 4px;">
                  Heeft u een iPhone met Face ID?
                </div>

                <!-- Description -->
                <p style="font-size: 12px; color: #666; line-height: 1.2; margin: 0;">
                  Scan voeten eenvoudig met <br>—<strong>FittrScan 3D</strong>—<br>
                  geen extra 3D-scanner apparatuur of handmatige uploads meer nodig!<br>
                  Gebruik uw iPhone om te scannen en open de scans direct hier vanuit de bibliotheek. </p>

                <div style="margin-top:12px;display:flex;flex-wrap:nowrap">
                  <q-btn color="secondary" rounded flat @click="downloadScannerApp">
                    <i class="fa-brands fa-app-store" style="font-size: 32px"></i>
                  </q-btn>

                  <q-btn color="secondary" rounded flat @click="copyToClipboard(url)
                    .then(() => {
                      // success!
                    })
                    .catch(() => {
                      // fail
                    });">
                    <i class="fa-regular fa-copy" style="font-size: 22px"></i>
                  </q-btn>
                </div>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <!-- Right Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="6" size-lg="4.5" size-xl="4.5">
            <ion-card style="padding: 0; margin-top: 8px; margin-left: 0; margin-right: 8px;" class="custom-card">
              <ion-card-content style="padding: 12px">
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="width: 100%; aspect-ratio: 4 / 5; border: 1px solid var(--ion-color-dark); border-radius: 6px">
                        <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                          <div style="width: 100%; height: 100%; border-radius: 5px" ref="target_right"></div>
                        </transition>
                        <q-inner-loading :showing="visible_right">
                          <q-spinner-hourglass size="50px" color="secondary"/>
                          <p style="font-size:1.5em"><b>{{ loading_text_right }}</b></p>
                        </q-inner-loading>
                      </div>
                    </ion-col>

                    <!-- Manual rotate & move controls -->
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="display: flex; flex-wrap: wrap; justify-content: space-evenly">
                        <ion-button @click="rotateModelOnYPlane('right', 1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnYPlane('right', -1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('right', -1, 0)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 1, 0)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, -1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, 1)" shape="round" fill="clear">
                          <i style="font-size: 20px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>

        </ion-row>
      </ion-grid>

      <!-- Modal for Upload & Library -->
      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setOpen(false, null)">Cancel</ion-button>
            </ion-buttons>
            <ion-buttons slot="end" v-if="contentType === 'upload'">

            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content v-if="contentType === 'upload'" class="ion-padding">

          <div style="margin-top:24px;">
            <ion-toolbar style="width:100%;margin-top: 16px; text-align: center">
              <ion-title>Vul onderstaande velden om de bestanden te uploaden</ion-title>
            </ion-toolbar>
            <div style="display: flex; flex-direction: row; justify-content: space-evenly">
              <q-input label="Klantnummer/geboortedatum" v-model="clientIdOne" ref="inputRef" type="text" name="clientIdOne" style="width: 40%"/>
              <q-input label="Eerste letters achternaam" v-model="clientIdTwo" ref="inputRef" type="text" name="clientIdTwo" style="width: 40%"/>
            </div>
          </div>

          <div style="width:100%;">
            <ion-toolbar style="margin-top: 16px; text-align: center">
              <ion-title>Upload 3D files</ion-title>
            </ion-toolbar>
            <div style="display: flex; flex-direction: row; justify-content: space-evenly">
              <div style="width:40%">
                <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                  <q-uploader @added="onAdded($event, 'left')" hide-upload-btn label="Links" style="width: 100%"/>
                </transition>
                <q-inner-loading label-class="text-teal" label="Bestanden worden geupload en verwerkt..." style="width: 100%;height:100%;position: relative!important;margin-top: -115px" :showing="files_loaded_left">
                  <q-spinner-gears size="50px" color="primary"/>
                </q-inner-loading>
              </div>
              <div style="width:40%">
                <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                  <q-uploader @added="onAdded($event, 'right')" hide-upload-btn label="Rechts" style="width: 100%"/>
                </transition>
                <q-inner-loading label-class="text-teal" label="Bestanden worden geupload en verwerkt..." style="width: 100%;height:100%;position: relative!important;margin-top: -115px" :showing="files_loaded_right">
                  <q-spinner-gears size="50px" color="primary"/>
                </q-inner-loading>
              </div>
            </div>
          </div>
          <div v-if="files_loaded_right === true && files_loaded_left === true" style="width:100%; text-align: center;margin-top:74px">
                        <p>De bestanden worden op de server gecontroleerd en verwerkt, dit kan soms één of twee minuten duren... </p>
          </div>
          <div v-if="files_processed_right === true && files_processed_left === true" style="width:100%; text-align: center;margin-top:74px">
<!--            <p>De bestanden worden op de server gecontroleerd en verwerkt, dit kan soms één of twee minuten duren... </p>-->
            <q-btn color="warning" @click="saveScans" label="Bevestig"/>
          </div>

        </ion-content>

        <ion-content v-if="contentType === 'library'" class="ion-padding">
          <q-list class="rounded-borders">
            <q-separator spaced/>
            <template v-for="item in library" :key="item.id">
              <q-item>
                <q-item-section avatar top>
                  <q-avatar color="grey" text-color="white">
                    <i style="font-size: 22px" class="fal fa-chart-scatter-3d"></i>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label style="font-weight: bold" lines="1">
                    {{ item.client_id_two + " " + item.client_id_one }}
                  </q-item-label>
                  <q-item-label caption>{{ normalizeDate(item.date) }}</q-item-label>
                  <q-item-label>Files:</q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_left }}</q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_right }}</q-item-label>
                  <q-item-label v-if="item.filename_inlay_left || item.filename_inlay_right">Inlay files:</q-item-label>
                  <q-item-label v-if="item.filename_inlay_left" style="margin-left: 16px" caption>{{ item.filename_inlay_left }}
                    <ion-button @click="downloadScanFromBrowser(item.filename_inlay_left)" fill="clear" shape="round" style="margin: -16px 32px 0 32px;">
                      <i style="font-weight: 500;
    font-size: 20px;" slot="icon-only" class="fal fa-download"></i></ion-button>
                  </q-item-label>
                  <q-item-label v-if="item.filename_inlay_right" style="margin-left: 16px" caption>{{ item.filename_inlay_right }}
                    <ion-button @click="downloadScanFromBrowser(item.filename_inlay_right)" fill="clear" shape="round" style="margin: -16px 32px 0 32px;">
                      <i style="font-weight: 500;
    font-size: 20px;" slot="icon-only" class="fal fa-download"></i></ion-button>
                  </q-item-label>
                </q-item-section>

                <q-item-section side>
                  <ion-button @click="loadScan(item.id)" fill="clear" shape="round">
                    <i style="font-size: 22px" class="fal fa-arrow-right"></i></ion-button>
                </q-item-section>
              </q-item>
              <q-separator spaced/>
            </template>
          </q-list>
        </ion-content>
      </ion-modal>
    </IonContent>
  </IonPage>
</template>

<script setup>
/* ------------------- ALL IMPORTS ------------------- */
import {
  IonButton, IonButtons, IonCard, IonCardContent, IonCol,
  IonContent, IonGrid, IonHeader, IonModal, IonPage,
  IonRow, IonToolbar, IonTitle
} from "@ionic/vue";
import {post} from "aws-amplify/api";
import {QMarkupTable, QInnerLoading, QSpinnerHourglass, copyToClipboard} from "quasar";
import {DataStore, SortDirection} from "aws-amplify/datastore";
import {downloadData, uploadData, getUrl} from "aws-amplify/storage";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {PLYLoader} from "three/examples/jsm/loaders/PLYLoader";

import {onBeforeUnmount, onMounted, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import outline_left from "../assets/outline_L.png";
import outline_right from "../assets/outline_R.png";
import FtrHeader from "../components/FtrHeader.vue";
import {helptext_3dscan_page} from "../locales/HelptTextContent";
import {DevFittr3DScans} from "../models";
import {useGlobalStore} from "../store/global";
import {Device} from '@capacitor/device';
import {Share} from '@capacitor/share';

async function isIOSWithFaceID() {
  const info = await Device.getInfo();
  if (info.platform === 'ios' || info.operatingSystem === 'ios') {
    console.log('Device is an iOS device');

    // Get the device model (e.g., "iPhone 15 Pro" or "iPad Pro (11-inch)")
    const model = info.model.toLowerCase();
    // Face ID was first introduced in iPhone X (2017) and iPad Pro (2018)
    // Any iPhone model >= X or iPad Pro >= 2018 should have Face ID (TrueDepth)
    const hasTrueDepth = (
        model.includes('iphone') && !model.includes('iphone 8') && !model.includes('iphone se') // iPhone X and above
    ) || (
        model.includes('ipad pro') && !model.includes('2017') // iPad Pro 2018 and later
    );
    console.log(hasTrueDepth ? 'TrueDepth Camera detected' : 'No TrueDepth Camera');
    return hasTrueDepth;
  } else {

    console.log('Not an iOS device');
    return false;
  }
}

const url = ref('https://apps.apple.com/us/app/fittrscan-3d/id6740188076')

async function downloadScannerApp() {

  const hasTrueDepth = await isIOSWithFaceID();
  if (hasTrueDepth === true) {
    window.location.href = url.value;
  } else {
    await Share.share({
      title: 'Dit apparaat ondersteunt geen TrueDepth-camera (Face ID)',
      text: "Deel deze link met een iPhone of iPad met een TrueDepth-camera (Face ID), of zoek in de App Store naar 'FittrScan 3D'.",
      url: url.value,
    });

  }
}

const store = useGlobalStore();
const router = useRouter();
const route = useRoute();
const visible_left = ref(false);
const visible_right = ref(false);
const loading_text_left = ref('');
const loading_text_right = ref('');
loading_text_left.value = 'Loading 3D file...';
loading_text_right.value = 'Loading 3D file...';
let rotationTimeout;
const isOpen = ref(false);
const contentType = ref("");
const library = ref();

const setOpen = async (open, content) => {
  if (content === "upload") {
    contentType.value = "upload";
  } else if (content === "library") {
    let category;
    if (route.params.category.includes('f')) {
      category = 'f';
    } else if (route.params.category.includes('m')) {
      category = 'm';
    }
    DataStore.observeQuery(
        DevFittr3DScans,
        p => p.and(p => [
          p.gender.contains(category),
          p.user.contains(store.logged_in_user.email),
        ]), {
          sort: s => s.date(SortDirection.DESCENDING)
        }
    ).subscribe(snapshot => {
      const {items, isSynced} = snapshot;
      library.value = items;
      contentType.value = "library";
      console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
    });
    /* library.value = await DataStore.query(
     DevFittr3DScans,
     (c) =>
     c.and((c) => [
     c.gender.contains(category),
     c.user.contains(store.logged_in_user.email),
     ]),
     {
     sort: (s) => s.date(SortDirection.DESCENDING),
     }
     );
     console.log(library.value); */
    contentType.value = "library";
  }
  isOpen.value = open;
};

const clientIdOne = ref("");
const clientIdTwo = ref("");
const filename_left = ref("");
const filename_right = ref("");
const file_left = ref();
const file_right = ref();
const width_left = ref();
const circumference_left = ref();
const length_left = ref();
const width_right = ref();
const circumference_right = ref();
const length_right = ref();
const files_loaded_left = ref(false);
const files_loaded_right = ref(false);
const files_processed_left = ref(false);
const files_processed_right = ref(false);

async function saveScans() {
  const category = JSON.parse(route.params.category);
  const user = await store.getLoggedInUser();
  console.log(user);
  visible_left.value = true;
  visible_right.value = true;
  const date = Date.now();
  const saved_model = await DataStore.save(
      new DevFittr3DScans({
        client_id_one: clientIdOne.value,
        client_id_two: clientIdTwo.value,
        user: user.email,
        gender: category.category,
        filename_left: filename_left.value,
        filename_right: filename_right.value,
        organisation: user["custom:organisation_id"],
        date: date,
      })
  );
  console.log(saved_model);
  await setOpen(false);
  await loadScan(saved_model.id)
}

// --------------------------------------------------------------------
// 3) Updated loadScan Function: Convert OBJ scans to binary STL if needed.
// --------------------------------------------------------------------

async function loadScan(id) {
  // Close modal and show loading indicator.
  await setOpen(false);
  visible_left.value = true;
  visible_right.value = true;

  // Query the scan.
  const scan = await DataStore.query(DevFittr3DScans, id);

  console.log(scan)
  // Start both downloads concurrently.
  const fileL = await downloadScan(scan.filename_left);
  console.log(fileL)
  const fileR = await downloadScan(scan.filename_right);


  // For each file, check if it's an OBJ.
  const leftFileData = {file: fileL.body, filename: scan.filename_left};

  const rightFileData = {file: fileR.body, filename: scan.filename_right};

  // Load the models concurrently using the (now assured) STL file.
  loadSTL("left", leftFileData.file, leftFileData.filename);
  loadSTL("right", rightFileData.file, rightFileData.filename);
}

// --------------------------------------------------------------------
// 4) Your Existing downloadScan Function (unchanged)
// --------------------------------------------------------------------

async function downloadScan(filename) {
  return await downloadData({
    path: "public/3DScans/" + filename,
    options: {
      onProgress: (event) => {
        console.log(event.transferredBytes);
      },
    },
  }).result;
}

async function downloadScanFromBrowser(filename) {
  const getUrlResult = await getUrl({
    path: "public/3DScans/" + filename,
    // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
    options: {
      validateObjectExistence: false,  // Check if object exists before creating a URL
    },
  });
  console.log('signed URL: ', getUrlResult.url);
  console.log('URL expires at: ', getUrlResult.expiresAt);
  //window.open(getUrlResult.url)
  window.location.href = getUrlResult.url;
}

async function onAdded(files, side) {
  const stamp = Date.now();
  if (side === "left") {
    files_loaded_left.value = true;
    files_processed_left.value = false;
    filename_left.value = stamp + "_" + files[0].name.replace('.obj', '').replace('.stl', '').replace('.ply', '') + "_processed.ply";
    file_left.value = files;
  } else {
    files_loaded_right.value = true;
    files_processed_right.value = false;
    filename_right.value = stamp + "_" + files[0].name.replace('.obj', '').replace('.stl', '').replace('.ply', '') + "_processed.ply";
    file_right.value = files;
  }

  const objectUrl = URL.createObjectURL(files[0]);
  const reader = new FileReader();

  reader.onload = async () => {
    // Revoke object URL after load
    URL.revokeObjectURL(objectUrl);

    try {
      let uploadComplete = false;
      // Monitor upload progress
      const upload_result = uploadData({
        path: "public/3DScans/" + stamp + "_" + files[0].name,
        data: files[0],
        options: {
          onProgress: ({transferredBytes, totalBytes}) => {
            if (totalBytes) {
              const progress = Math.round((transferredBytes / totalBytes) * 100);
              console.log(`Upload progress: ${progress}%`);
              if (progress === 100) {
                uploadComplete = true;
              }
            }
          },
        },
      });

      // Wait until progress reaches 100%
      while (!uploadComplete) {
        await new Promise(resolve => setTimeout(resolve, 100)); // Check every 100ms
      }

      console.log("Upload complete!");
      console.log(upload_result); // ✅ Only logs when done
      console.log(side)
      try {
        const restOperation = post({
          apiName: "uploadfootscan",
          path: "/uploadfootscan",
          options: {
            body: {
              bucket: "fittrapp-storage12052-dev",
              s3_key: "public/3DScans/" + stamp + "_" + files[0].name,
              side: side,
            }
          },
        });
        const {body} = await restOperation.response;
        const str = await body.json();
        console.log(str)
      } catch (e) {
        console.log("GET call failed: ", JSON.parse(e.response.body));
      }

      async function pollForProcessedFile(filePath, interval = 1000, timeout = 900000) {
        const startTime = Date.now();
        while (Date.now() - startTime < timeout) {
          try {
            // Use the provided snippet to check for file existence.
            const url = await getUrl({
              path: filePath,
              options: {
                validateObjectExistence: true // This will throw an error if the file doesn't exist.
              }
            });
            console.log("Processed file found at:", url);
            return url;
          } catch (error) {
            console.log("File not found yet. Retrying in", interval / 1000, "seconds...");
          }
          await new Promise((resolve) => setTimeout(resolve, interval));
        }
        throw new Error("Timeout: Processed file not found within the given timeframe.");
      }

// Usage example:
      pollForProcessedFile("public/3DScans/" + stamp + "_" + files[0].name.replace('.obj', '').replace('.stl', '').replace('.ply', '') + "_processed.ply")
          .then((url) => {
            console.log("Processing complete. Processed file URL:", url);
            if (side === "left") {
              files_processed_left.value = true;
              files_loaded_left.value = false;
            } else {
              files_processed_right.value = true;
              files_loaded_right.value = false;
            }
            // Update your UI to indicate processing completion, or load the file.
          })
          .catch((err) => {
            console.error("Error polling for file:", err);
            // Handle the error, e.g., notify the user that processing failed or timed out.
          });

    } catch (error) {
      console.log("Error:", error); // Logs error if upload fails
    }
  };

  // Read the file as ArrayBuffer (start reading the file)
  reader.readAsArrayBuffer(files[0]);
}


/* ------------------- HELPER: Format Date ------------------- */
function normalizeDate(date) {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const mon = String(d.getMonth() + 1).padStart(2, "0");
  const yr = d.getFullYear();
  const hrs = String(d.getHours()).padStart(2, "0");
  const mins = String(d.getMinutes()).padStart(2, "0");
  return `${day}-${mon}-${yr} ${hrs}:${mins}`;
}

/* ------------------------------------------------------
 3D Scenes, Cameras, Renderers, & OnMounted
 ------------------------------------------------------ */
const target_left = ref(null);
const target_right = ref(null);

let scene_left, camera_left, renderer_left, controls_left, footModel_left = null;
let scene_right, camera_right, renderer_right, controls_right, footModel_right = null;

function createSceneAndRenderer(container) {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);

  // Target aspect ratio: 2 (width) : 3 (height)
  const targetAspect = 4 / 5;

  // Function to compute dimensions that maintain the target aspect ratio.
  function computeDimensions() {
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    let newWidth, newHeight;
    if (containerWidth / containerHeight > targetAspect) {
      // Container is too wide: limit by height.
      newHeight = containerHeight;
      newWidth = newHeight * targetAspect;
    } else {
      // Container is too tall: limit by width.
      newWidth = containerWidth;
      newHeight = newWidth / targetAspect;
    }
    return {newWidth, newHeight};
  }

  const {newWidth, newHeight} = computeDimensions();

  // Create a camera with the computed aspect ratio.
  const camera = new THREE.PerspectiveCamera(40, newWidth / newHeight, 0.1, 5000);
  camera.position.set(0, 450, 0);
  camera.lookAt(0, 0, 0);
  camera.up.set(0, 1, 0);

  const renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setSize(newWidth, newHeight);
  renderer.shadowMap.enabled = true;
  renderer.localClippingEnabled = true;

  // Add lights as before.
  const ambientLight = new THREE.AmbientLight(0xffffff, 1.2);
  scene.add(ambientLight);

  const dirLight1 = new THREE.DirectionalLight(0xffffff, 2);
  dirLight1.position.set(50, 50, 100);
  dirLight1.castShadow = true;
  scene.add(dirLight1);

  const dirLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
  dirLight2.position.set(-50, 50, -100);
  dirLight2.castShadow = true;
  scene.add(dirLight2);

  const dirLight3 = new THREE.DirectionalLight(0xffffff, 1);
  dirLight3.position.set(0, 100, 0);
  dirLight3.castShadow = true;
  scene.add(dirLight3);

  const gridHelper = new THREE.GridHelper(400, 40);
  scene.add(gridHelper);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;

  // Resize handler to maintain the 2:3 aspect ratio.
  const resizeRenderer = () => {
    const {newWidth, newHeight} = computeDimensions();
    renderer.setSize(newWidth, newHeight);
    camera.aspect = newWidth / newHeight;
    camera.updateProjectionMatrix();
  };
  const resizeObserver = new ResizeObserver(resizeRenderer);
  resizeObserver.observe(container);

  onBeforeUnmount(() => resizeObserver.disconnect());
  resizeRenderer();

  return {scene, camera, renderer, controls};
}

function animateBoth() {
  requestAnimationFrame(animateBoth);
  if (controls_left && scene_left && camera_left && renderer_left) {
    controls_left.update();
    renderer_left.render(scene_left, camera_left);
  }
  if (controls_right && scene_right && camera_right && renderer_right) {
    controls_right.update();
    renderer_right.render(scene_right, camera_right);
  }
}

onMounted(() => {
  if (target_left.value) {
    const setupLeft = createSceneAndRenderer(target_left.value);
    scene_left = setupLeft.scene;
    camera_left = setupLeft.camera;
    renderer_left = setupLeft.renderer;
    controls_left = setupLeft.controls;
    target_left.value.appendChild(renderer_left.domElement);
    console.log("Left scene created with lights:", scene_left.children.filter(c => c.isLight));

    // Add event listeners for left side (mousedown, touch, etc.)
    target_left.value.addEventListener("mousedown", onMouseDownLeft);
    target_left.value.addEventListener("mousemove", onMouseMoveLeft);
    target_left.value.addEventListener("mouseup", onMouseUpLeft);
    target_left.value.addEventListener("touchstart", onTouchStartLeft);
    target_left.value.addEventListener("touchmove", onTouchMoveLeft);
    target_left.value.addEventListener("touchend", onTouchEndLeft);

    loadFootOutline("left", scene_left);
    console.log("Left scene children:", scene_left.children);
  }

  if (target_right.value) {
    const setupRight = createSceneAndRenderer(target_right.value);
    scene_right = setupRight.scene;
    camera_right = setupRight.camera;
    renderer_right = setupRight.renderer;
    controls_right = setupRight.controls;
    target_right.value.appendChild(renderer_right.domElement);
    console.log("Right scene created with lights:", scene_right.children.filter(c => c.isLight));

    // Add event listeners for right side.
    target_right.value.addEventListener("mousedown", onMouseDownRight);
    target_right.value.addEventListener("mousemove", onMouseMoveRight);
    target_right.value.addEventListener("mouseup", onMouseUpRight);
    target_right.value.addEventListener("touchstart", onTouchStartRight);
    target_right.value.addEventListener("touchmove", onTouchMoveRight);
    target_right.value.addEventListener("touchend", onTouchEndRight);

    loadFootOutline("right", scene_right);
    console.log("Right scene children:", scene_right.children);
  }

  animateBoth();
});

onBeforeUnmount(() => {
  if (target_left.value) {
    target_left.value.removeEventListener("mousedown", onMouseDownLeft);
    target_left.value.removeEventListener("mousemove", onMouseMoveLeft);
    target_left.value.removeEventListener("mouseup", onMouseUpLeft);

    target_left.value.removeEventListener("touchstart", onTouchStartLeft);
    target_left.value.removeEventListener("touchmove", onTouchMoveLeft);
    target_left.value.removeEventListener("touchend", onTouchEndLeft);
  }

  if (target_right.value) {
    target_right.value.removeEventListener("mousedown", onMouseDownRight);
    target_right.value.removeEventListener("mousemove", onMouseMoveRight);
    target_right.value.removeEventListener("mouseup", onMouseUpRight);

    target_right.value.removeEventListener("touchstart", onTouchStartRight);
    target_right.value.removeEventListener("touchmove", onTouchMoveRight);
    target_right.value.removeEventListener("touchend", onTouchEndRight);
  }
});

/* ------------------- FOOT OUTLINE & BOX LINES ------------------- */
function loadFootOutline(side, scene) {
  const outlineImg = (side === "left") ? outline_left : outline_right;
  const texLoader = new THREE.TextureLoader();
  texLoader.load(outlineImg, (texture) => {
    const planeGeo = new THREE.PlaneGeometry(150, 300);
    const planeMat = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    const footOutline = new THREE.Mesh(planeGeo, planeMat);
    footOutline.rotation.x = -Math.PI / 2;
    footOutline.position.set(0, 0.1, 0);
    scene.add(footOutline);
  });
}

/* ------------------- BUILD BOUNDING MESH & CHECK ------------------- */
function createCustomFootBoundingLines(side, scene) {
  // Define key points (adjust coordinates as needed)
  let midHeelLeft;
  let midHeelRight;
  let heelLeft;
  let heelRight;
  let middleBackLegLeft;
  let middleBackLegRight;
  let upperBackLegLeft;
  let upperBackLegRight;
  let upperFrontalLegLeft;
  let upperFrontalLegRight;
  let middleFrontalLegLeft;
  let middleFrontalLegRight;
  let upperMidFootLeft;
  let upperMidFootRight;
  let bottomBallLeft;
  let bottomBallRight;
  let upperBallLeft;
  let upperBallRight;
  let medial_point;
  let lateral_point;
  let bottomToesLeft;
  let bottomToesRight;
  let upperToesLeft;
  let upperToesRight;

  if (route?.params?.category?.includes("f")) {
    midHeelLeft = new THREE.Vector3(-40, 0.1, 80);
    midHeelRight = new THREE.Vector3(40, 0.1, 80);
    heelLeft = new THREE.Vector3(-40, 0.1, 150);
    heelRight = new THREE.Vector3(40, 0.1, 150);
    middleBackLegLeft = new THREE.Vector3(-50, 100, 150);
    middleBackLegRight = new THREE.Vector3(50, 100, 150);
    upperBackLegLeft = new THREE.Vector3(-50, 300, 150);
    upperBackLegRight = new THREE.Vector3(50, 300, 150);
    upperFrontalLegLeft = new THREE.Vector3(-50, 300, 35);
    upperFrontalLegRight = new THREE.Vector3(50, 300, 35);
    middleFrontalLegLeft = new THREE.Vector3(-50, 120, 35);
    middleFrontalLegRight = new THREE.Vector3(50, 120, 35);
    upperMidFootLeft = new THREE.Vector3(-50, 100, 15);
    upperMidFootRight = new THREE.Vector3(50, 100, 15);
    bottomBallLeft = side === "left"
        ? new THREE.Vector3(-65, 0.1, -31)
        : new THREE.Vector3(-65, 0.1, -63);
    bottomBallRight = side === "left"
        ? new THREE.Vector3(65, 0.1, -63)
        : new THREE.Vector3(65, 0.1, -31);
    upperBallLeft = side === "left"
        ? new THREE.Vector3(-65, 50, -31)
        : new THREE.Vector3(-65, 50, -63);
    upperBallRight = side === "left"
        ? new THREE.Vector3(65, 50, -63)
        : new THREE.Vector3(65, 50, -31);
    medial_point = side === "left" ? -140 : -160;
    lateral_point = side === "left" ? -160 : -140;
    bottomToesLeft = side === "left"
        ? new THREE.Vector3(-60, 0.1, medial_point)
        : new THREE.Vector3(-60, 0.1, medial_point);
    bottomToesRight = side === "left"
        ? new THREE.Vector3(60, 0.1, lateral_point)
        : new THREE.Vector3(60, 0.1, lateral_point);
    upperToesLeft = side === "left"
        ? new THREE.Vector3(-60, 30, medial_point)
        : new THREE.Vector3(-60, 35, medial_point);
    upperToesRight = side === "left"
        ? new THREE.Vector3(60, 35, lateral_point)
        : new THREE.Vector3(60, 30, lateral_point);
  } else if (route?.params?.category?.includes("m")) {
    midHeelLeft = new THREE.Vector3(-40, 0.1, 80);
    midHeelRight = new THREE.Vector3(40, 0.1, 80);
    heelLeft = new THREE.Vector3(-40, 0.1, 150);
    heelRight = new THREE.Vector3(40, 0.1, 150);
    middleBackLegLeft = new THREE.Vector3(-50, 100, 150);
    middleBackLegRight = new THREE.Vector3(50, 100, 150);
    upperBackLegLeft = new THREE.Vector3(-50, 300, 150);
    upperBackLegRight = new THREE.Vector3(50, 300, 150);
    upperFrontalLegLeft = new THREE.Vector3(-50, 300, 25);
    upperFrontalLegRight = new THREE.Vector3(50, 300, 25);
    middleFrontalLegLeft = new THREE.Vector3(-50, 140, 25);
    middleFrontalLegRight = new THREE.Vector3(50, 140, 25);

    upperMidFootLeft = new THREE.Vector3(-50, 120, 5);
    upperMidFootRight = new THREE.Vector3(50, 120, 5);

    bottomBallLeft = side === "left"
        ? new THREE.Vector3(-65, 0.1, -31)
        : new THREE.Vector3(-65, 0.1, -63);
    bottomBallRight = side === "left"
        ? new THREE.Vector3(65, 0.1, -63)
        : new THREE.Vector3(65, 0.1, -31);
    upperBallLeft = side === "left"
        ? new THREE.Vector3(-65, 50, -31)
        : new THREE.Vector3(-65, 50, -63);
    upperBallRight = side === "left"
        ? new THREE.Vector3(65, 50, -63)
        : new THREE.Vector3(65, 50, -31);
    medial_point = side === "left" ? -140 : -160;
    lateral_point = side === "left" ? -160 : -140;
    bottomToesLeft = side === "left"
        ? new THREE.Vector3(-60, 0.1, medial_point)
        : new THREE.Vector3(-60, 0.1, medial_point);
    bottomToesRight = side === "left"
        ? new THREE.Vector3(60, 0.1, lateral_point)
        : new THREE.Vector3(60, 0.1, lateral_point);
    upperToesLeft = side === "left"
        ? new THREE.Vector3(-60, 30, medial_point)
        : new THREE.Vector3(-60, 30, medial_point);
    upperToesRight = side === "left"
        ? new THREE.Vector3(60, 30, lateral_point)
        : new THREE.Vector3(60, 30, lateral_point);
  }
  // Build an ordered list of points for the horizontal boundaries.
  // For example, let’s assume we want the following chains:
  // • Left side: bottomToesLeft → upperToesLeft → upperBallLeft → bottomBallLeft → heelLeft
  // • Right side: bottomToesRight → upperToesRight → upperBallRight → bottomBallRight → heelRight
  // • Plus some cross-connections between the sides.
  const pts = [
    // left side connections
    upperFrontalLegLeft, middleFrontalLegLeft,
    middleFrontalLegLeft, upperMidFootLeft,
    upperMidFootLeft, upperBallLeft,
    upperBallLeft, upperToesLeft,
    upperToesLeft, bottomToesLeft,
    bottomToesLeft, bottomBallLeft,
    bottomBallLeft, heelLeft,
    heelLeft, middleBackLegLeft,
    middleBackLegLeft, upperBackLegLeft,
    upperBackLegLeft, upperFrontalLegLeft,
    // right side connections
    upperFrontalLegRight, middleFrontalLegRight,
    middleFrontalLegRight, upperMidFootRight,
    upperMidFootRight, upperBallRight,
    upperBallRight, upperToesRight,
    upperToesRight, bottomToesRight,
    bottomToesRight, bottomBallRight,
    bottomBallRight, heelRight,
    heelRight, middleBackLegRight,
    middleBackLegRight, upperBackLegRight,
    upperBackLegRight, upperFrontalLegRight,
    // horizontal lines (left to right (lateral to medial for left foot and medial to lateral for right foot) connections)
    bottomToesLeft, bottomToesRight,
    upperToesLeft, upperToesRight,
    upperBallLeft, upperBallRight,
    upperMidFootLeft, upperMidFootRight,
    middleFrontalLegLeft, middleFrontalLegRight,
    upperFrontalLegLeft, upperFrontalLegRight,
    upperBackLegLeft, upperBackLegRight,
    heelLeft, heelRight,
    midHeelLeft, midHeelRight,
    bottomBallLeft, bottomBallRight
  ];
  // Create line segments by connecting consecutive points (and closing the loop).
  const linePairs = [];
  for (let i = 0; i < pts.length; i = i + 2) {
    const start = pts[i];
    const end = pts[(i + 1) % pts.length];
    linePairs.push(start, end);
  }
  const positions = [];
  for (const pt of linePairs) {
    positions.push(pt.x, pt.y, pt.z);
  }
  const lineGeom = new THREE.BufferGeometry();
  lineGeom.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
  // Create a LineSegments object with a visible color.
  const lineMat = new THREE.LineBasicMaterial({color: 0xff0000});
  const wireframeLines = new THREE.LineSegments(lineGeom, lineMat);
  scene.add(wireframeLines);
  return wireframeLines;
}

// Helper: Extract endpoints (in order) from the LineSegments geometry.
// We assume that the geometry stores the endpoints sequentially.
function getWireframeEndpoints(lineSegments) {
  const posArray = lineSegments.geometry.attributes.position.array;
  const endpoints = [];
  // For a closed loop, we'll just extract one endpoint per segment.
  for (let i = 0; i < posArray.length; i += 3) {
    endpoints.push(new THREE.Vector3(posArray[i], posArray[i + 1], posArray[i + 2]));
  }
  return endpoints;
}

function removeCustomFootBoundingLines(wireframeLines, scene) {
  if (!wireframeLines) return;
  scene.remove(wireframeLines);
  if (wireframeLines.geometry) {
    wireframeLines.geometry.dispose();
  }
  if (wireframeLines.material) {
    if (Array.isArray(wireframeLines.material)) {
      wireframeLines.material.forEach(mat => mat.dispose());
    } else {
      wireframeLines.material.dispose();
    }
  }
}

function loadSTL(side, file, filename) {
  const {
    scene,
    widthRef,
    circumferenceRef,
    lengthRef
  } = (side === "left")
      ? {
        scene: scene_left,
        widthRef: width_left,
        circumferenceRef: circumference_left,
        lengthRef: length_left
      }
      : {
        scene: scene_right,
        widthRef: width_right,
        circumferenceRef: circumference_right,
        lengthRef: length_right
      };

  // 2) Choose loader based on extension
  let loader;
  loader = new PLYLoader();

  const reader = new FileReader();
  reader.readAsArrayBuffer(file);

  // 4) When the file has loaded, parse and build your foot model
  reader.onload = (e) => {
    let contents = e.target.result;

    let footModel;
// STL/PLY case: parse into a geometry
    const geometry = loader.parse(contents);
    geometry.computeVertexNormals();

// Check if the model has color attributes
    const hasColors = geometry.attributes.color !== undefined;

// Define material
    const material = new THREE.MeshPhongMaterial({
      vertexColors: hasColors, // Use vertex colors if present
      color: hasColors ? undefined : new THREE.Color(0xDFC4B2), // Skin-like color if no colors are in the model
      shininess: 50,
      transparent: true,
      opacity: 1,
    });

    footModel = new THREE.Mesh(geometry, material);
    footModel.castShadow = true;
    footModel.receiveShadow = true;

    // 6) Create bounding lines or wireframe skeleton for that side
    const wireframeLines = createCustomFootBoundingLines(side, scene);
    const wireframeEndpoints = getWireframeEndpoints(wireframeLines);

    // 7) Add foot model to the scene
    scene.add(footModel);
    if (side === "left") {
      loading_text_left.value = 'Voet correct positioneren...';
    } else {
      loading_text_right.value = 'Voet correct positioneren...';
    }

    removeCustomFootBoundingLines(wireframeLines, scene);

    // 9) If we're loading left foot, store reference; likewise for right foot
    if (side === "left") {
      footModel_left = footModel;
      files_loaded_left.value = true;
      visible_left.value = false;
    } else {
      footModel_right = footModel;
      files_loaded_right.value = true;
      visible_right.value = false;
    }
    // Hide any loading UI, etc.
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  };
}

/* ------------------- MEASUREMENT LOGIC ------------------- */
function removeSpheresAndMeasurements(sceneRef) {
  sceneRef.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      sceneRef.remove(child);
    }
  });
}

function measureFoot(footModel, scene, side) {
  if (side === "left") {
    loading_text_left.value = 'Voet correct positioneren...';
    visible_left.value = true;
  } else {
    loading_text_right.value = 'Voet correct positioneren...';
    visible_right.value = true;
  }

  if (!footModel) {
    console.warn("No foot model loaded.");
    return {width: 0, circumference: 0, length: 0};
  }

  // Remove any existing measurement spheres from the scene.
  scene.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      scene.remove(child);
    }
  });

  // Helper to draw spheres for debugging/visualizing measurements.
  function drawSphere(position, color = 0xff0000) {
    const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(1, 8, 8),
        new THREE.MeshBasicMaterial({color})
    );
    sphere.position.copy(position);
    scene.add(sphere);
  }

  // --------------------------------------------------------
  // 1) Collect all vertices in WORLD SPACE from footModel.
  //    (Works for either a single Mesh or a Group.)
  // --------------------------------------------------------
  const worldVertices = [];
  footModel.traverse((child) => {
    if (
        child.isMesh &&
        child.geometry &&
        child.geometry.attributes?.position
    ) {
      const posAttr = child.geometry.attributes.position;
      for (let i = 0; i < posAttr.count; i++) {
        const localVertex = new THREE.Vector3(
            posAttr.getX(i),
            posAttr.getY(i),
            posAttr.getZ(i)
        );
        // Transform from local to world space.
        const worldVertex = localVertex.applyMatrix4(child.matrixWorld);
        worldVertices.push(worldVertex);
      }
    }
  });

  if (worldVertices.length === 0) {
    console.warn("No vertices found in foot model (empty geometry?).");
    return {width: 0, circumference: 0, length: 0};
  }

  // Pre-sort the vertices by their y value.
  const sortedVertices = worldVertices.slice().sort((a, b) => a.y - b.y);

  // --------------------------------------------------------
  // 2) Find the widest points.
  //    We use a scanning approach along a horizontal reference line.
  // --------------------------------------------------------
  // Define measurement reference line for each foot.
  const horizontalLineStart =
      side === "left" ? {x: -100, z: -25} : {x: -100, z: -68};
  const horizontalLineEnd =
      side === "left" ? {x: 100, z: -68} : {x: 100, z: -25};

  const inwardStep = 1.0;
  const heightStep = 1.0;
  const maxHeight = 50;
  const startHeight = 0;
  const tolerance = 1.0;

  // Left foot vs. right foot scanning factors.
  let leftFactor, rightFactor;
  if (side === "left") {
    leftFactor = 0;
    rightFactor = 1;
  } else {
    // Invert scanning direction for the right foot.
    leftFactor = 1;
    rightFactor = 0;
  }

  function interpolateZ(x) {
    const factor =
        (x - horizontalLineStart.x) /
        (horizontalLineEnd.x - horizontalLineStart.x);
    return (
        horizontalLineStart.z +
        factor * (horizontalLineEnd.z - horizontalLineStart.z)
    );
  }

  // Optimized findVertex: because sortedVertices is sorted by y,
  // we simply iterate in order and return the first vertex that meets our tolerance conditions.
  function findVertex(x, z) {
    for (let i = 0; i < sortedVertices.length; i++) {
      const vertex = sortedVertices[i];
      if (vertex.y < startHeight) continue;
      if (vertex.y > maxHeight) break;
      if (
          Math.abs(vertex.x - x) <= tolerance &&
          Math.abs(vertex.z - z) <= tolerance
      ) {
        return vertex;
      }
    }
    return null;
  }

  let widestLeftPoint = null;
  let widestRightPoint = null;

  // Adjust factors until both anchor points are found.
  while (true) {
    const leftX =
        horizontalLineStart.x +
        leftFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const leftZ = interpolateZ(leftX);

    const rightX =
        horizontalLineStart.x +
        rightFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const rightZ = interpolateZ(rightX);

    if (!widestLeftPoint) widestLeftPoint = findVertex(leftX, leftZ);
    if (!widestRightPoint) widestRightPoint = findVertex(rightX, rightZ);

    if (widestLeftPoint && widestRightPoint) break;

    if (side === "left") {
      if (!widestLeftPoint) leftFactor += inwardStep / 200;
      if (!widestRightPoint) rightFactor -= inwardStep / 200;
      if (leftFactor > 1 || rightFactor < 0) break;
    } else {
      if (!widestLeftPoint) leftFactor -= inwardStep / 200;
      if (!widestRightPoint) rightFactor += inwardStep / 200;
      if (leftFactor < 0 || rightFactor > 1) break;
    }
  }

  if (!widestLeftPoint || !widestRightPoint) {
    console.warn("Unable to find widest points.");
    return {width: 0, circumference: 0, length: 0};
  }

  // Draw spheres at the widest points (for debugging).
  drawSphere(widestLeftPoint, 0xff0000);
  drawSphere(widestRightPoint, 0x0000ff);

  // The "width" is the distance between these widest points.
  const width = widestLeftPoint.distanceTo(widestRightPoint);

  // --------------------------------------------------------
  // 3) Measure circumference (using top and bottom markers).
  // --------------------------------------------------------
  const stepSize = 2; // horizontal step
  const yStep = 1; // vertical step
  const maxDistance = 50; // limit for searching up/down
  const toleranceC = 1.0;

  // Optimized version of findMarkerOnModelSurface using the sorted array.
  // We look for the first vertex near the startPoint in x and z,
  // that lies in the desired direction (above or below).
  function findMarkerOnModelSurface(startPoint, direction) {
    for (let i = 0; i < sortedVertices.length; i++) {
      const vertex = sortedVertices[i];
      if (
          Math.abs(vertex.x - startPoint.x) <= toleranceC &&
          Math.abs(vertex.z - startPoint.z) <= toleranceC
      ) {
        if (direction > 0 && vertex.y >= startPoint.y) {
          return vertex;
        } else if (direction < 0 && vertex.y <= startPoint.y) {
          return vertex;
        }
      }
    }
    return null;
  }

  const distanceBetweenWidestPoints = widestLeftPoint.distanceTo(
      widestRightPoint
  );
  const stepCount = Math.floor(distanceBetweenWidestPoints / stepSize);

  const topMarkers = [];
  const bottomMarkers = [];

  for (let i = 0; i <= stepCount; i++) {
    const t = i / stepCount;
    // Linear interpolation between widestLeftPoint & widestRightPoint.
    const startPoint = new THREE.Vector3().lerpVectors(
        widestLeftPoint,
        widestRightPoint,
        t
    );

    const markerAbove = findMarkerOnModelSurface(startPoint, +1);
    const markerBelow = findMarkerOnModelSurface(startPoint, -1);

    if (markerAbove) {
      topMarkers.push(markerAbove);
      drawSphere(markerAbove, 0x00ff00); // green
    }
    if (markerBelow) {
      bottomMarkers.push(markerBelow);
      drawSphere(markerBelow, 0x0000ff); // blue
    }
  }

  function measureLineLength(markers) {
    if (markers.length < 2) return 0;
    let length = 0;
    for (let i = 0; i < markers.length - 1; i++) {
      length += markers[i].distanceTo(markers[i + 1]);
    }
    return length;
  }

  const topLineLength = measureLineLength(topMarkers);
  const bottomLineLength = measureLineLength(bottomMarkers);
  const circumference = topLineLength + bottomLineLength;

  // --------------------------------------------------------
  // 4) Measure the "length" of the foot.
  //    (Similar to measureModelLengthWithLines logic)
  // --------------------------------------------------------
  function measureModelLengthWithLines() {
    // Filter out vertices above y=100.
    const filteredVerts = worldVertices.filter((v) => v.y <= 80);
    if (filteredVerts.length === 0) {
      console.warn("No vertices found at or below y=100. Returning length=0.");
      return 0;
    }

    // Find minZ & maxZ among the filtered vertices.
    let minZ = Infinity;
    let maxZ = -Infinity;
    for (const vertex of filteredVerts) {
      if (vertex.z < minZ) minZ = vertex.z;
      if (vertex.z > maxZ) maxZ = vertex.z;
    }

    const tol = 1.0;
    const stepSizeLocal = 1.0;
    let forwardLineZ = maxZ + 10;
    let backwardLineZ = minZ - 10;
    let forwardContactPoint = null;
    let backwardContactPoint = null;

    function findLineContact(z) {
      let farthestTop = null;
      let farthestBottom = null;
      for (const vertex of filteredVerts) {
        if (Math.abs(vertex.z - z) <= tol) {
          if (!farthestTop || vertex.y > farthestTop.y) farthestTop = vertex;
          if (!farthestBottom || vertex.y < farthestBottom.y)
            farthestBottom = vertex;
        }
      }
      return {farthestTop, farthestBottom};
    }

    while (true) {
      const {farthestTop, farthestBottom} = findLineContact(forwardLineZ);
      if (farthestTop && farthestBottom) {
        forwardContactPoint = {top: farthestTop, bottom: farthestBottom};
        break;
      }
      forwardLineZ -= stepSizeLocal;
    }

    while (true) {
      const {farthestTop, farthestBottom} = findLineContact(backwardLineZ);
      if (farthestTop && farthestBottom) {
        backwardContactPoint = {top: farthestTop, bottom: farthestBottom};
        break;
      }
      backwardLineZ += stepSizeLocal;
    }

    // Optionally place spheres at the contact points.
    function createSphere(position, color) {
      const sphereGeometry = new THREE.SphereGeometry(1, 8, 8);
      const sphereMaterial = new THREE.MeshBasicMaterial({color});
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }

    createSphere(forwardContactPoint.top, 0xff0000);
    createSphere(forwardContactPoint.bottom, 0xff0000);
    createSphere(backwardContactPoint.top, 0x0000ff);
    createSphere(backwardContactPoint.bottom, 0x0000ff);

    // Compute the length as the difference between the forward and backward line Z values.
    return forwardLineZ - backwardLineZ;
  }

  const length = measureModelLengthWithLines();

  if (side === "left") {
    visible_left.value = false;
  } else {
    visible_right.value = false;
  }

  return {
    width,
    circumference,
    length,
  };
}

/* ------------------- REMOVE SCANS ------------------- */
function removeScans() {
  if (footModel_left && scene_left) {
    scene_left.remove(footModel_left);
    footModel_left.geometry?.dispose();
    if (Array.isArray(footModel_left.material)) {
      footModel_left.material.forEach((m) => m.dispose());
    } else {
      footModel_left.material?.dispose();
    }
    footModel_left = null;
  }

  if (footModel_right && scene_right) {
    scene_right.remove(footModel_right);
    footModel_right.geometry?.dispose();
    if (Array.isArray(footModel_right.material)) {
      footModel_right.material.forEach((m) => m.dispose());
    } else {
      footModel_right.material?.dispose();
    }
    footModel_right = null;
  }

  files_loaded_left.value = false;
  files_loaded_right.value = false;
  width_left.value = null;
  width_right.value = null;
  circumference_left.value = null;
  circumference_right.value = null;
  length_left.value = null;
  length_right.value = null;

  removeSpheresAndMeasurements(scene_left);
  removeSpheresAndMeasurements(scene_right);
}

/* ------------------- MISC NAVIGATE ------------------- */
async function navigate() {
  store.scanLengthLeft = Math.round(length_left.value);
  store.scanWidthLeft = Math.round(width_left.value);
  store.scanCircumfenceLeft = Math.round(circumference_left.value);

  store.scanLengthRight = Math.round(length_right.value);
  store.scanWidthRight = Math.round(width_right.value);
  store.scanCircumfenceRight = Math.round(circumference_right.value);

  await router.push("/osb/foot-dimensions/" + store.category);
}

/* ------------------- MOVE/ROTATE MANUAL ------------------- */
function rotateModelOnZPlane(side, angleDeg) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left, widthRef: width_left,
            circumferenceRef: circumference_left, lengthRef: length_left
          }
          : {
            footModel: footModel_right, scene: scene_right, widthRef: width_right,
            circumferenceRef: circumference_right, lengthRef: length_right
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  let deltaRad = THREE.MathUtils.degToRad(angleDeg);
  footModel.rotation.z += deltaRad;

  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }, 1000);
}

function rotateModelOnYPlane(side, angleDeg) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left, widthRef: width_left,
            circumferenceRef: circumference_left, lengthRef: length_left
          }
          : {
            footModel: footModel_right, scene: scene_right, widthRef: width_right,
            circumferenceRef: circumference_right, lengthRef: length_right
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  let deltaRad = THREE.MathUtils.degToRad(angleDeg);
  footModel.rotation.y += deltaRad;

  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }, 1000);
}

function moveModelOnZPlane(side, xOff, zOff) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left,
            widthRef: width_left, circumferenceRef: circumference_left, lengthRef: length_left
          }
          : {
            footModel: footModel_right, scene: scene_right,
            widthRef: width_right, circumferenceRef: circumference_right, lengthRef: length_right
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  footModel.position.x += xOff;
  footModel.position.z += zOff;

  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }, 1000);
}

/* ------------------- DRAG HANDLERS ------------------- */
let isDraggingLeft = false;
let isDraggingRight = false;
const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
let dragStartPositionLeft = new THREE.Vector3();
let dragStartPositionRight = new THREE.Vector3();

function startDraggingModel(side, clientX, clientY) {
  const {camera, footModel, controls} =
      (side === "left")
          ? {camera: camera_left, footModel: footModel_left, controls: controls_left}
          : {camera: camera_right, footModel: footModel_right, controls: controls_right};

  if (!footModel) return;

  const container = (side === "left") ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObject(footModel);
  if (intersects.length > 0) {
    if (side === "left") {
      isDraggingLeft = true;
      controls.enabled = false;
      dragStartPositionLeft.copy(intersects[0].point);
    } else {
      isDraggingRight = true;
      controls.enabled = false;
      dragStartPositionRight.copy(intersects[0].point);
    }
  }
}

function dragModel(side, clientX, clientY) {
  const {camera, footModel, scene} =
      (side === "left")
          ? {camera: camera_left, footModel: footModel_left, scene: scene_left}
          : {camera: camera_right, footModel: footModel_right, scene: scene_right};

  if (!footModel) return;
  const dragging = (side === "left") ? isDraggingLeft : isDraggingRight;
  if (!dragging) return;

  removeSpheresAndMeasurements(scene);

  const container = (side === "left") ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);

  // project on plane y=0
  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
  const intersectP = new THREE.Vector3();
  raycaster.ray.intersectPlane(plane, intersectP);

  if (side === "left") {
    footModel.position.set(
        footModel.position.x + (intersectP.x - dragStartPositionLeft.x),
        footModel.position.y,
        footModel.position.z + (intersectP.z - dragStartPositionLeft.z)
    );
    dragStartPositionLeft.copy(intersectP);
  } else {
    footModel.position.set(
        footModel.position.x + (intersectP.x - dragStartPositionRight.x),
        footModel.position.y,
        footModel.position.z + (intersectP.z - dragStartPositionRight.z)
    );
    dragStartPositionRight.copy(intersectP);
  }
}

function stopDraggingModel(side) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef, controls} =
      (side === "left")
          ? {
            footModel: footModel_left, scene: scene_left,
            widthRef: width_left, circumferenceRef: circumference_left,
            lengthRef: length_left, controls: controls_left
          }
          : {
            footModel: footModel_right, scene: scene_right,
            widthRef: width_right, circumferenceRef: circumference_right,
            lengthRef: length_right, controls: controls_right
          };

  if (side === "left" && isDraggingLeft) {
    isDraggingLeft = false;
    controls.enabled = true;
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  } else if (side === "right" && isDraggingRight) {
    isDraggingRight = false;
    controls.enabled = true;
    const m = measureFoot(footModel, scene, side);
    widthRef.value = m.width;
    circumferenceRef.value = m.circumference;
    lengthRef.value = m.length;
  }
}

function onMouseDownLeft(e) { startDraggingModel("left", e.clientX, e.clientY); }

function onMouseMoveLeft(e) { dragModel("left", e.clientX, e.clientY); }

function onMouseUpLeft() { stopDraggingModel("left"); }

function onTouchStartLeft(e) {
  if (e.touches.length === 1) {
    startDraggingModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveLeft(e) {
  if (e.touches.length === 1) {
    dragModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndLeft() { stopDraggingModel("left"); }

function onMouseDownRight(e) { startDraggingModel("right", e.clientX, e.clientY); }

function onMouseMoveRight(e) { dragModel("right", e.clientX, e.clientY); }

function onMouseUpRight() { stopDraggingModel("right"); }

function onTouchStartRight(e) {
  if (e.touches.length === 1) {
    startDraggingModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveRight(e) {
  if (e.touches.length === 1) {
    dragModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndRight() { stopDraggingModel("right"); }
</script>

<style scoped>
canvas {
  border-radius: 15px !important;
}
</style>